<template>
  <v-theme-provider dark>
    <section id="hero-alt">
      <base-img
        :height="$vuetify.breakpoint.mdAndUp ? 350 : 225"
        :gradient="gradient"
        :src="src"
        color="primary"
        flat
        max-width="100%"
        tile
      >
        <v-row
          align="center"
          class="ma-0 fill-height text-center"
          justify="center"
        >
          <v-col cols="12">
            <base-heading v-if="title" :title="title" space="2" weight="500" />

            <base-title
              v-if="subtitle"
              :title="subtitle"
              space="6"
              weight="normal"
              size-mobile="text-subtitile-1"
            />

            <base-divider color="white" dense />
          </v-col>
        </v-row>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "HeroSection",

  provide: {
    heading: { align: "center" },
  },

  computed: {
    gradient() {
      // Option A
      // const color = `${this.$vuetify.theme.themes.light.primary}CC`;
      // const overlay = RGBAtoCSS(HexToRGBA(color));
      // return `to top, ${overlay}, ${overlay}`;

      // Option B
      // return 'to right, rgba(0, 0, 0, .8), rgba(0, 0, 0, .4)'

      // Option C
      const color = `${this.$vuetify.theme.themes.light.secondary}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    title() {
      return this.$route.meta.title;
    },
    subtitle() {
      return this.$route.meta.subtitle;
    },
    src() {
      return this.$route.meta.src;
    },
  },
};
</script>
